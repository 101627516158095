// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("./images/pic1.jpg");
var ___HTML_LOADER_IMPORT_1___ = require("./images/pic2.jpg");
var ___HTML_LOADER_IMPORT_2___ = require("./images/pic3.jpg");
var ___HTML_LOADER_IMPORT_3___ = require("./images/pic4.jpg");
var ___HTML_LOADER_IMPORT_4___ = require("./images/pic5.jpg");
var ___HTML_LOADER_IMPORT_5___ = require("./images/pic6.jpg");
var ___HTML_LOADER_IMPORT_6___ = require("./images/pic7.jpg");
// Module
var ___HTML_LOADER_REPLACER_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACER_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACER_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACER_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var ___HTML_LOADER_REPLACER_4___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_4___);
var ___HTML_LOADER_REPLACER_5___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_5___);
var ___HTML_LOADER_REPLACER_6___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_6___);
var code = "<div class=scene-content> <div id=photo_container> <div class=photo1> <div class=polaroid> <img src=" + ___HTML_LOADER_REPLACER_0___ + " /> </div> <div class=caption> <p style=font-weight:700;color:#000;text-align:center> myself</p> </div> </div> <div class=photo2> <div class=polaroid> <img src=" + ___HTML_LOADER_REPLACER_1___ + " /> </div> <div class=caption> <p style=font-weight:700;color:#000;text-align:center> Family </p> </div> </div> <div class=photo3> <div class=polaroid> <img src=" + ___HTML_LOADER_REPLACER_2___ + " /> </div> <div class=caption> <p style=font-weight:700;color:#000;text-align:center> Bubbles </p> </div> </div> <div class=photo4> <div class=polaroid> <img src=" + ___HTML_LOADER_REPLACER_3___ + " /> </div> <div class=caption> <p style=font-weight:700;color:#000;text-align:center> Friends</p> </div> </div> <div class=photo5> <div class=polaroid> <img src=" + ___HTML_LOADER_REPLACER_4___ + " /> </div> <div class=caption> <p style=font-weight:700;color:#000;text-align:center> Colleagues</p> </div> </div> <div class=photo6> <div class=polaroid> <img src=" + ___HTML_LOADER_REPLACER_5___ + " /> </div> <div class=caption> <p style=font-weight:700;color:#000;text-align:center> Childhood Friends</p> </div> </div> <div class=photo7> <div class=polaroid> <img src=" + ___HTML_LOADER_REPLACER_6___ + " /> </div> <div class=caption> <p style=font-weight:700;color:#000;text-align:center> Home</p> </div> </div> </div> <div id=skills_container> </div> </div> <div class=svg></div> ";
// Exports
module.exports = code;