export default [
  "Dependency Injections",
  "Salesforce Batch apex and Queues",
  "Salesforce development",
  "Salesforce in Health Sector",
  "Integration Patterns in Salesforce",
  "Sharing and visibility Designer in Salesforce",
  "Node and Salesforce Functions",
  "Clean Code",
  "Salesforce Service Console",
  "Salesforce Lightning Console",
  "Angular vs LWC",
  "Shadow DOM in LWC(Lightning Web Component)",
  "Continuous integration",
  "Test-driven development",
  "Dev Ops",
  "Full Stack Development",
  "Salesforce Technical Architect",
  "React",
  "Redux",
  "Salesforce Application Architect",
  "Salesforce System Architect",
  "Jest Testing",
  "Visual regression testing",
  "Mocha Automation Testing",
  "User Interface",
  "React Native",
  "Focus on affordance",
  "Forward thinking",
  "Leadership",
  "Mentoring",
  "Internet of things",
  "Async functions",
];
